import React, { Component } from "react";

import Geosuggest from "react-geosuggest";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Leaflet from "leaflet";

import Modal from "simple-react-modal";

import pin from "../../../../../../assets/icons/pin_small.png";
//import pin from "../../../../../../assets/icons/pin_bandera.png";
import icon from "../../../../../../assets/my_location_marker.png";
import pinBig from "../../../../../../assets/icons/pin.png";
import mapStyles from "../../../../../../utils/mapStyle";
import Button from "../../../../../../shared/components/Button";
import { helpers } from "../../../../../../utils/helpers";
import "./styles.css";
import axios from "axios";

const { compose, withProps, withStateHandlers } = require("recompose");
const googleScriptSrc =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAa6MJtEisuiJW107yTrKiYihHX00iu0bg&v=3.exp&libraries=geometry,drawing,places";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

const defaultMapOptions = {
  styles: mapStyles
};

class LeafletMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMarker: null,
      center: { lat: -34.6134019, lng: -58.4993903 },
      user: null,
      zoom: 12,
      address: "",
      showModal: false,
      modalMessage: "",
      agencies: []
    };
  }

  componentDidMount() {
    this._getLocation();
    this._getAgencies();
  }

  _getAgencies = () => {
    const agenciesUrl = process.env.REACT_APP_API_URL + `/api/agencies`;
    axios
      .get(agenciesUrl)
      .then(response => {
        let agencias = [];

        response.data.forEach(function(elemento) {
          let agencia = {
            address:
              elemento.Direccion +
              ", " +
              elemento.Localidad +
              ", " +
              elemento.Provincia,
            name: elemento.RazonSocial,
            phone: elemento.Telefono,
            position: { lat: elemento.Latitud, lng: elemento.Longitud },
            time: elemento.Horarios
          };
          agencias.push(agencia);
        });

        this.setState({
          agencies: agencias
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  _getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          user: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          zoom: 12
        });
      });
    }
  };

  _useMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        let { latitude, longitude } = position.coords;
        helpers.geocode
          .fromCoords(latitude, longitude)
          .then(response => {
            const address = response.results[0].formatted_address;
            this.setState({
              center: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              user: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
              zoom: 12,
              address
            });
          })
          .catch(e => {
            console.log(e);
          });
      });
    }
  };

  _handleInput = (prop, value) => {
    this.setState({ [prop]: value });
  };

  _searchAddress = () => {
    let { address } = this.state;
    let searchAddress = address;
    if (searchAddress.toLowerCase().indexOf("argentina") === -1) {
      searchAddress += ", Argentina";
    }

    helpers.geocode
      .fromAddress(searchAddress)
      .then(response => {
        console.log(response);
        if (response.results.length > 0) {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            zoom: 12,
            center: { lat, lng }
          });
        } else {
          let msg = (
            <div>
              <p>La dirección ingresada es inexistente.</p>
              <p>Por favor, volvé a intentarlo.</p>
            </div>
          );
          this.setState({
            showModal: true,
            modalMessage: msg
          });
        }
      })
      .catch(e => {
        console.log(e);
        let msg = (
          <div>
            <p>Se ha producido un error en la búsqueda.</p>
            <p>Por favor, volvé a intentarlo.</p>
          </div>
        );
        this.setState({
          showModal: true,
          modalMessage: msg
        });
      });
  };

  render() {
    if (!(window.google && window.google.maps)) {
      setTimeout(() => {
        this.setState({ address: "" });
      }, 1000);
    }

    let myIcon = Leaflet.icon({
      iconUrl: icon,
      popupAnchor: [-3, -76]
    });

    let marker = Leaflet.icon({
      iconUrl: pin,
      popupAnchor: [0, -18],
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    });

    const { agencies } = this.state;

    return (
      <section className="home-agencies">
        {/* <div className={"agencies-form"}>
          <div className={"agencies-title"}>
            <h2>Agencias</h2>
          </div>
          <div className={"agencies-form-title"}>
            Encontrá la Agencia más conveniente para realizar tus envíos,
            verificá los horarios y cómo llegar.
          </div>
          {/*<div className={"agencies-form-input"}>
            <div className={"label-div"}>Buscar por dirección</div>
            
            <Geosuggest
              className={"geo-input"}
              country={"AR"}
              googleMaps={window.google.maps}
              onKeyDown={ev => this._handleInput("address", ev.target.value)}
              onSuggestSelect={suggest => {
                if (suggest) {
                  let newState = {};
                  if (suggest.location) {
                    newState["center"] = suggest.location;
                  }
                  if (suggest.label) {
                    newState["address"] = suggest.label;
                  }
                  this.setState(newState);
                }
              }}
              placeholder={"Calle y número, provincia"}
            />
          </div>*/}
        {/* <div className={"geo-button"}>
            <div onClick={this._useMyLocation}>
              <img src={pinBig} alt={"Ubicación"} />
              Usar mi ubicación actual
            </div>
          </div> */}
        {/*<div className={"address-button-container"}>
            <Button onClick={this._searchAddress}>Buscar</Button>
          </div>*/}
        {/* </div>  */}
        <Map
          center={this.state.center}
          style={{ height: 400, zIndex: 1 }}
          zoom={this.state.zoom}
        >
          <TileLayer
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            maxZoom={16}
            minZoom={5}
          />
          {this.state.user && this.state.user.lat && this.state.user.lng && (
            <Marker position={this.state.user} icon={myIcon} />
          )}

          <MarkerClusterGroup className="markercluster-map">
            {agencies &&
              agencies.map(agency => {
                let contact = agency.phone
                  ? agency.phone
                  : "Sin teléfono disponible";
                let mail = agency.email ? agency.email : "";
                if (mail !== "") {
                  contact.concat(` - ${mail}`);
                }
                return (
                  <Marker position={agency.position} icon={marker}>
                    <Popup>
                      <div className="info-box">
                        <h2>{agency.name}</h2>
                        <p>{agency.address}</p>
                        <p>{contact}</p>
                        <p className="mb-2">{agency.time}</p>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
          </MarkerClusterGroup>
        </Map>
        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {this.state.modalMessage}
            <div>
              <Button
                className="quote"
                onClick={() => this.setState({ showModal: false })}
              >
                Entendido
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

export default LeafletMap;
