import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import contacto from "assets/summary/contacto.png";
// import cotizar from "assets/summary/cotizar.png";
// import packs from "assets/summary/packs.png";
// import rastreo from "assets/summary/rastreo.png";
// import agencias from "assets/summary/agencias.png";
// import arrow from "assets/icons/flecha.svg";
// import pickup_movil from "assets/summary/pickup_movil.png";
// import pickup_web from "assets/summary/pickup_web.png";
// import entrega_agencia from "assets/summary/entrega_agencia.png";
import arrow from "assets/icons/flecha.svg";
import contacto from "assets/summary/mobile/atencion-al-cliente.png";
import cotizar from "assets/summary/mobile/cotiza-tu-envio.png";
import packs from "assets/summary/mobile/tips-de-empaquetado.png";
import rastreo from "assets/summary/mobile/seguimiento-de-tu-envio.png";
import agencias from "assets/summary/mobile/buscador-de-agencias.png";
import pickup_movil from "assets/summary/mobile/pick-up.png";
import pickup_web from "assets/summary/mobile/retiro-en-domicilio.png";
import entrega_agencia from "assets/summary/mobile/entrega-en-agencia.png";
import "./styles.css";

class QuickAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    };
  }

  _handleInput = ev => {
    let value = ev.target.value;

    if (value.length > 12) {
      return false;
    }

    this.setState({ code: value });
  };

  _submitSearch = ev => {
    ev.preventDefault();

    let { code } = this.state;
    console.log(code);
    this.props.history.push(`/tracking${code !== "" ? `/${code}` : ""}`);
  };

  render() {
    let { code } = this.state;
    return (
      <div className="quick-access-section">
        <div className="sidebar-title">
          <h1>Acceso rápido</h1>
        </div>
        <div className="side-item" onClick={this._submitSearch}>
          <img className="side-img" src={rastreo} alt="Tracking" />
          <span className="side-title">Seguimiento</span>
          <img className="side-img arrow" src={arrow} alt="Next" />
        </div>
        <div className="side-item separator">
          <form onSubmit={this._submitSearch}>
            <input
              type="text"
              value={code}
              onChange={this._handleInput}
              className="tracking-input"
              placeholder="número de seguimiento"
            />
            <button type={"submit"} style={{ display: "none" }} />
          </form>
        </div>
        {/* <Link to="/product/pm">
          <div className="side-item separator">
            <img className="side-img" src={pickup_movil} alt="Pickup Móvil" />
            <span className="side-title">Pickup Móvil</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link> */}

        <Link to="/product/rd">
          <div className="side-item separator">
            <img className="side-img" src={pickup_web} alt="Retiros Web" />
            <span className="side-title">Retiro en domicilio</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to="/product/ea">
          <div className="side-item separator">
            <img
              className="side-img"
              src={entrega_agencia}
              alt="Entrega Agencia"
            />
            <span className="side-title">Entrega en Agencia</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to={"/agencies"}>
          <div className="side-item separator">
            <img className="side-img" src={agencias} alt="Tracking" />
            <span className="side-title">Buscador de Agencias</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>
        <Link to="/tips">
          <div className="side-item separator">
            <img className="side-img" src={packs} alt="Tracking" />
            <span className="side-title">Tips de empaquetado</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to={"/quote"}>
          <div className="side-item separator">
            <img className="side-img" src={cotizar} alt="Tracking" />
            <span className="side-title">Cotiza tu envio</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>
        {/* 

        <Link to={"/pickup-home"}>
          <div className="side-item separator">
            <img className="side-img" src={cotizar} alt="Generar Envíos" />
            <span className="side-title">Generar Envíos</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

         */}
        <Link to="/support">
          <div className="side-item separator">
            <img className="side-img" src={contacto} alt="Tracking" />
            <span className="side-title">Atencion al Cliente</span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>
      </div>
    );
  }
}

export default withRouter(QuickAccess);
